<template>
    <div class="StaffEdit">
        <el-card shadow="never" style="margin-top: 8px">
            <el-page-header @back="goBack" />
        </el-card>
        <el-card shadow="never" style="margin-top: 8px">
            <el-form ref="form" :model="form" label-width="80px" size="small">
                <el-form-item label="员工类型">
                    <el-radio-group
                        v-model="staff.type"
                        @change="handleChange"
                        :disabled="!hasPrivilege('biz.system.staff.type.edit')"
                    >
                        <el-radio :label="1">业务型</el-radio>
                        <el-radio :label="0">管理型</el-radio>
                    </el-radio-group>
                </el-form-item>
                <el-form-item label="用户名">
                    <span>{{ staff.username }}</span>
                </el-form-item>
                <el-form-item label="姓名">
                    <el-input v-model="staff.realName" />
                </el-form-item>
                <el-form-item label="性别">
                    <el-radio-group v-model="staff.sex">
                        <el-radio :label="0">男</el-radio>
                        <el-radio :label="1">女</el-radio>
                    </el-radio-group>
                </el-form-item>
                <el-form-item label="年龄">
                    <el-input v-model="staff.age" @keyup.native="proving" />
                </el-form-item>
                <el-form-item label="手机号">
                    <span>{{ staff.mobile }}</span>
                </el-form-item>
                <el-form-item label="身份证号">
                    <span>{{ staff.idNumber }}</span>
                </el-form-item>
                <el-form-item label="机构类型">
                    <el-radio-group v-model="staff.deptType" @change="handleChange">
                        <el-radio :label="3">门店</el-radio>
                        <el-radio :label="2">配送中心</el-radio>
                        <el-radio :label="1">总部电商</el-radio>
                    </el-radio-group>
                </el-form-item>
                <el-form-item label="">
                    <el-button type="primary" @click="handleSave" size="small">保存</el-button>
                </el-form-item>
            </el-form>
        </el-card>
    </div>
</template>

<script>
import UrlUtils from '../../../../js/UrlUtils';

export default {
    name: 'StaffEdit',
    props: ['form'],
    data() {
        return {
            staff: {},
            url: {
                save: '/system/staff/patchExtend/',
                searchID: '/system/staff/info_i/',
            },
        };
    },
    mounted() {
        this.staff = JSON.parse(JSON.stringify(this.form));
        this.getIdNumber();
    },
    methods: {
        handleSave() {
            const _this = this;
            const _p = {
                deptCode: _this.staff.deptCode,
                type: _this.staff.type,
                realName: _this.staff.realName,
                sex: _this.staff.sex,
                age: _this.staff.age,
                deptType: _this.staff.deptType,
            };
            if (_p.realName == '') {
                this.$alert('您的姓名未填写,请及时填写', '提示', { type: 'warning' });
                return;
            }
            if (_p.age == '') {
                this.$alert('您的年龄未填写,请及时填写', '提示', { type: 'warning' });
                return;
            } else {
                if (_p.age > 100) {
                    this.$alert('员工的年龄应在100以下,请正确填写', '提示', { type: 'warning' });
                    return;
                }
            }
            UrlUtils.PatchRemote(this, this.url.save + this.staff.code, _p, null, () => {
                _this.$message.success('保存成功');
                clearTimeout();
                setTimeout(() => {
                    _this.goBackAndReload();
                }, 1000);
            });
        },
        //查身份证
        getIdNumber() {
            // 查身份证号
            this.$http.get(this.url.searchID + '/' + this.form.id).then((rsp) => {
                this.staff.idNumber = rsp.data.data.idNumber;
            });
        },
        handleChange() {
            this.$alert('修改员工类型和机构类型,将导致员工归属或管理的机构数据丢失,请小心操作', '提示', {
                type: 'warning',
            });
        },
        proving() {
            this.staff.age = this.staff.age.replace(/[^\.\d]/g, '');
            this.staff.age = this.staff.age.replace('.', '');
        },
    },
};
</script>

<style scoped>
.el-input {
    width: auto;
}
</style>
